<template>
  <v-card
    :to="linkToDashboard"
    class="mx-auto my-2"
    max-width="374"
    elevation="2"
    color="grey lighten-4"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-blue"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      class="white--text align-end"
      height="300"
      :src="fleet.cover_photo"
      v-if="fleet.cover_photo"
    >
      <v-card-title class="box-text font-weight-light">
        Truck #{{ fleet.truck_id }}
        <v-divider></v-divider>
        <span class="country-flag">
          <country-flag
            v-if="fleet.country"
            :country="fleet.country | countryCode"
            size="normal"
          />
        </span>
      </v-card-title>
    </v-img>
    <v-img
      class="white--text align-end"
      height="300"
      src="../assets/truck.png"
      v-else
    >
      <v-card-title class="box-text font-weight-light">
        Truck #{{ fleet.truck_id }}
        <v-divider></v-divider>
        <span class="country-flag">
          <country-flag
            v-if="fleet.country"
            :country="fleet.country | countryCode"
            size="normal"
          />
        </span>
      </v-card-title>
    </v-img>

    <v-card-text>
      <span class="d-inline-block text-truncate" style="max-width: 300px">
        <v-icon>mdi-google-maps</v-icon>
        {{ fleet.city }}, {{ fleet.region }}
      </span>
      <v-spacer></v-spacer>
      <v-icon>mdi-chart-areaspline</v-icon>
      Average Daily Impressions
      <strong>{{ fleet.avg_daily_impressions }}</strong>
    </v-card-text>

    <v-card-actions>
      <v-btn class="primary" :to="`/analytics/id=${fleet.truck_id}`"
        >View Analytics</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "DashboardFleetCard",
  components: {
    CountryFlag: () => import("vue-country-flag"),
  },
  props: ["fleet"],
  computed: {
    linkToDashboard() {
      return `/analytics/id=${this.fleet.truck_id}`;
    },
  },
};
</script>

<style>
.box-text {
  background: rgba(29, 46, 67, 0.8);
}
</style>
